import type { WritableComputedRef } from 'vue'

export const INFINITE_SCROLL_THROTTLE_FOR_LOADING = 200
export const INFINITE_SCROLL_SENSITIVE_AREA_FOR_LOADING = 500
export const INFINITE_SCROLL_SCROLLING_DEBOUNCED_TIMER_DELAY = 1000
export const INFINITE_SCROLL_SCROLLING_TIMER_DELAY = 100 // мінімальна затримка для оптимізації

export type InfiniteScrollOptions = { direction?: 'up' | 'down' }

export class InfiniteScroll {
    private disabled = true
    private direction: 'up' | 'down' = 'up'
    private throttleBlock = false
    private previousScrollTop = 0
    private scrollingTimer: ReturnType<typeof setTimeout>
    private scrollingTimerDebounced: ReturnType<typeof setTimeout>

    public isScrolling: Ref<boolean> = ref<boolean>(false)
    public isScrollingDebounced: Ref<boolean> = ref<boolean>(false)

    constructor(
        public readonly container: Ref<HTMLElement>,
        public readonly noMoreItems: WritableComputedRef<boolean>,
        public readonly loading: Ref<boolean>,
        public readonly load: Function,
        options: InfiniteScrollOptions = {}
    ) {
        const {
            direction = 'up'
        } = options

        this.direction = direction
    }

    private setIsScrollingFalse(): void {
        clearTimeout(this.scrollingTimer)
        clearTimeout(this.scrollingTimerDebounced)

        this.scrollingTimer = setTimeout(() => {
            this.isScrolling.value = false
        }, INFINITE_SCROLL_SCROLLING_TIMER_DELAY)

        this.scrollingTimerDebounced = setTimeout(() => {
            this.isScrollingDebounced.value = false
        }, INFINITE_SCROLL_SCROLLING_DEBOUNCED_TIMER_DELAY)
    }

    public activate(): void {
        this.disabled = false
    }

    public deactivate(): void {
        this.disabled = true
    }

    public onScroll(): void {
        if (!this.container.value) {
            return
        }

        if (!this.isScrolling.value) {
            this.isScrolling.value = true
        }

        if (!this.isScrollingDebounced.value) {
            this.isScrollingDebounced.value = true
        }

        this.setIsScrollingFalse()

        let scrollDirectionActive: boolean
        let loadSensitiveZoneReached: boolean

        if (this.direction === 'up') {
            scrollDirectionActive = this.previousScrollTop > this.container.value.scrollTop
            loadSensitiveZoneReached = this.container.value.scrollTop < INFINITE_SCROLL_SENSITIVE_AREA_FOR_LOADING
        } else {
            const { scrollHeight, clientHeight, scrollTop } = this.container.value

            scrollDirectionActive = this.previousScrollTop < scrollTop
            // eslint-disable-next-line max-len
            loadSensitiveZoneReached = (scrollHeight - clientHeight - scrollTop) < INFINITE_SCROLL_SENSITIVE_AREA_FOR_LOADING
        }

        this.previousScrollTop = this.container.value.scrollTop

        if (
            this.disabled
            || this.throttleBlock
            || this.noMoreItems.value
            || this.loading.value
            || !scrollDirectionActive
            || !loadSensitiveZoneReached
        ) {
            return
        }

        this.throttleBlock = true

        this.load()

        setTimeout(() => {
            this.throttleBlock = false
        }, INFINITE_SCROLL_THROTTLE_FOR_LOADING)
    }
}
